@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
    background-color: #8ed8f8;
    margin: 0;
    overflow: auto;
    font-family: "Montserrat", sans-serif;
}


::-webkit-scrollbar {
  display: none;
}